.view-products {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .products-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    width: 250px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .image-preview img {
    margin: 10px 0;
    width: 100%;
    border-radius: 4px;
  }
  
  .product-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .edit-button,
  .delete-button {
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .edit-button {
    background-color: #4caf50;
    color: white;
    text-decoration: none;
  }
  
  .edit-button:hover {
    background-color: #45a049;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
    width: 50%;
  }
  
  .delete-button:hover {
    background-color: #d32f2f;
  }
  