.add-product-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .add-product-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: calc(100% - 10px);
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTSAwIDAgTCA1IDUgTCAxMCAweiIgZmlsbD0iIzAwMDAwMCIgLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  
  .image-previews {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-previews img {
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: darkpurple;
  }
  
  .add-button {
    width: 100%;
    align-self: center;
    align-items: center;
  }
