.navbar {
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 10px;
}

.nav-link {
  margin: 0 10px;
  text-decoration: none;
  color: black;
}

.nav-link.active-link {
  font-weight: bold;
}
